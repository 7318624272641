import React, { useState } from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import CarouselContainer from '../../Carousel/CarouselContainer'
import documentToReactComponents from '../../../utils/richTextUtils'
import { ParseConfiguration } from '../../../utils/helpers'

export default function MedicalTeamComponent({ content }) {
  const { configuration } = content
  const {
    linkClassName,
    linkText,
    carouselSettings,
    expandable
  } = ParseConfiguration(configuration)

  const [expandParagraph, setExpand] = useState({})

  function setExpandHandler(key) {
    const _expandParagraph = { ...expandParagraph }
    _expandParagraph[key] = !_expandParagraph[key]
    setExpand(_expandParagraph)
  }

  return (
    <>
      {content.pageTitle && (
        <div className="pt-12 pb-10 mx-auto text-3xl text-center text-primary-base sm:pt-20">
          <h2>{content.pageTitle}</h2>
        </div>
      )}
      <div
        className="w-full max-w-5xl mx-auto"
        data-contentfulid={content.contentful_id}
      >
        <div className="mx-6 text-center text-black markdown landing">
          {documentToReactComponents({
            richTextDocument: content.content.json
          })}
        </div>
      </div>
      <div className="flex justify-center w-full px-16 py-10 mx-auto max-w-7xl lg:px-12 sm:px-24">
        <CarouselContainer
          sliderSettings={carouselSettings || personCarouselSettings}
        >
          {content.references
            .filter((r) => r.__typename === 'ContentfulPerson')
            .map((person) => (
              <div key={person.id} className="flex flex-col justify-center">
                <div className="flex justify-center pb-10">
                  <Img
                    className="rounded-full"
                    fixed={person.image.fixed}
                    alt={person.image.title}
                  />
                </div>
                <div>
                  <h3 className="px-6 text-2xl text-center text-black lg:px-0">{`${
                    person.name
                  }${person.title ? `, ${person.title}` : ''}`}</h3>
                </div>
                <div
                  style={{
                    height:
                      !expandable || expandParagraph[person.id]
                        ? 'auto'
                        : '65px'
                  }}
                  className="mx-6 overflow-hidden text-center text-black markdown"
                  dangerouslySetInnerHTML={{
                    __html: person.shortBio.childMarkdownRemark.html
                  }}
                />
                {expandable && (
                  <div
                    className="font-medium text-center underline cursor-pointer text-primary-base"
                    onClick={setExpandHandler.bind(null, person.id)}
                  >
                    {' '}
                    {expandParagraph[person.id] ? 'See Less' : '... See More'}
                  </div>
                )}
              </div>
            ))}
        </CarouselContainer>
      </div>
      <div className="mx-auto">
        <Link
          to={'/team'}
          className={
            linkClassName ||
            'block mx-auto md:mx-0 md:inline-block cursor-pointer btn btn--primary hover:bg-primary-hover bold text-white hover:text-white mt-6 px-6 py-3 leading-tight rounded focus:outline-none my-2 no-underline'
          }
        >
          {linkText || 'View All Medical Team'}
        </Link>
      </div>
    </>
  )
}

const personCarouselSettings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
  className: 'round-arrows-big',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}
