import { Helmet } from 'react-helmet'
import React from 'react'

export const NoIndex = () => {
  return (
    <Helmet
      meta={[
        {
          name: 'robots',
          content: 'noindex'
        }
      ]}
    />
  )
}
