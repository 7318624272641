import React, { Fragment, Component, createRef } from 'react'
import Slider from 'react-slick'

// Import css files
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'

import './CarouselStyle.scss'

export default class extends Component {
  state = {
    slideIndex: 0
  }

  constructor(props) {
    super(props)
    this.slider = createRef()
  }

  componentDidUpdate(prevProps) {
    const { index } = this.props
    if (!isNaN(index) && prevProps.index !== index) {
      this.slider.slickGoTo(index)
    }
  }

  render() {
    const { sliderSettings, afterChange, children } = this.props

    const settings = {
      dots: true,
      infinite: false,
      slidesToShow: 5,
      className: 'align-left',
      arrows: false,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 4.3,
            slidesToScroll: 4,
            dots: true
          }
        },
        {
          breakpoint: 577,
          settings: {
            slidesToShow: 1.8,
            slidesToScroll: 1,
            dots: true
          }
        },
        {
          breakpoint: 401,
          settings: {
            slidesToShow: 1.8,
            slidesToScroll: 1,
            dots: true
          }
        }
      ],
      ...sliderSettings
    }

    return (
      <Fragment>
        <div className="inline-block w-full">
          <Slider
            ref={(slider) => (this.slider = slider)}
            {...settings}
            afterChange={afterChange}
          >
            {children}
          </Slider>
        </div>
      </Fragment>
    )
  }
}
