import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import classNames from 'classnames'
import CarouselContainer from '../components/Carousel/CarouselContainer'
import documentToReactComponents from '../utils/richTextUtils'
import MedicalTeamComponent from '../components/LandingPages/components/MedicalTeamComponent'
import { NoIndex } from '../components/NoIndex'

const AboutPage = ({ data: { content } }) => {
  const { references } = content

  const imgBoxCarouselSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    className: 'line-arrows-big',
    responsive: []
  }

  function RenderReference({ index, reference }) {
    if (
      reference.references &&
      reference.references[0].__typename === 'ContentfulPerson'
    ) {
      return (
        <div className="flex flex-col w-full pb-16 -mt-10 bg-grey-lightest md:mt-0">
          <MedicalTeamComponent content={reference} />
        </div>
      )
    }

    const title = reference.title || reference.pageTitle
    const content = reference.content || {}
    const subtitleJson = content.json
    const subtitleMarkdown =
      content.childMarkdownRemark && content.childMarkdownRemark.html

    const klassNames = classNames(
      'flex flex-wrap flex-col-reverse w-full lg:max-w-6xl lg:mx-auto',
      {
        'md:flex-row-reverse': index % 2,
        'md:flex-row': !(index % 2)
      }
    )

    // noinspection JSConstructorReturnsPrimitive
    return title && (subtitleJson || subtitleMarkdown) ? (
      <div className={klassNames}>
        <div className="w-full pb-10 md:w-1/2 md:pb-0">
          {reference.attachment ? (
            <RenderImageBox image={reference.attachment} />
          ) : (
            <CarouselContainer sliderSettings={imgBoxCarouselSettings}>
              {reference.images.map((i) => (
                <Link to={`/quiz/${i.description}`}>
                  <RenderImageBox image={i} />
                </Link>
              ))}
            </CarouselContainer>
          )}
        </div>
        <div className="flex items-center w-full pb-10 md:w-1/2 md:pb-0">
          <RenderTextBox
            title={title}
            subtitleJson={subtitleJson}
            subtitleMarkdown={subtitleMarkdown}
          />
        </div>
      </div>
    ) : null
  }

  function RenderTextBox({ title, subtitleMarkdown, subtitleJson }) {
    return (
      <div className="px-8">
        <div className="text-2xl text-primary-base">{title}</div>
        {subtitleJson && (
          <div className="markdown text-grey-dark md:text-base">
            {documentToReactComponents({ richTextDocument: subtitleJson })}
          </div>
        )}
        {subtitleMarkdown && (
          <div
            className="markdown text-grey-dark md:text-base"
            dangerouslySetInnerHTML={{
              __html: subtitleMarkdown
            }}
          />
        )}
      </div>
    )
  }

  function RenderImageBox({ image }) {
    return <Img fluid={image.fluid} alt={image.title} />
  }

  const bottomImage = content.images && content.images[0]

  return (
    <div className="w-full">
      <NoIndex />
      <div className="w-full px-6 py-20 mt-20 text-2xl text-center md:mt-16 lg:max-w-5xl lg:mx-auto sm:text-3xl text-primary-base md:py-32 bold">
        <h1>
          {documentToReactComponents({
            richTextDocument: content.content.json
          })}
        </h1>
      </div>
      <div className="flex flex-wrap w-full">
        {references.map((reference, index) => (
          <RenderReference
            key={reference.id}
            index={index}
            reference={reference}
          />
        ))}
      </div>
      <div
        className="relative mx-auto shadow-md"
        style={{ maxWidth: '1900px' }}
      >
        <h2
          className="absolute z-10 text-3xl md:text-5xl text-primary-base"
          style={{ left: '50%', top: '50%', transform: 'translate(0, -50%)' }}
        >
          {bottomImage.description}
        </h2>
        <Img fluid={bottomImage.fluid} />
      </div>
    </div>
  )
}

export const query = graphql`
  query AboutUsQuery {
    content: contentfulEvergreenContent(
      readableId: { eq: "layout-about-us3" },
      node_locale: { eq: "en-US" }
    ) {
      ...EvergreenFragment
      content {
        json
      }
      references {
        ... on ContentfulEvergreenContent {
          id
          name
          pageTitle
          content {
            json
          }
          references {
            ... on ContentfulPerson {
              id
              name
              title
              shortBio {
                childMarkdownRemark {
                  html
                }
              }
              image {
                id
                fixed(width: 200, height: 200, quality: 100) {
                  ...GatsbyContentfulFixed
                }
              }
            }
          }
          images {
            id
            title
            description
            fluid(maxWidth: 640, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
      images {
        id
        title
        description
        fluid(maxWidth: 1900) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default AboutPage
